// src/pages/MyPlant.js
import React from "react";

const MyPlant = () => {
  return (
    <div>
      <h1>My Plant Page</h1>
      <p>Track the growth of your plant based on your journaling progress.</p>
    </div>
  );
};

export default MyPlant;
