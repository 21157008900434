import React, { useState } from 'react';
import { CancelIcon } from '../../assets/icons/icons'; // Import cancel icon
import './style.css'; // Import styles

const GuidedPromptModal = ({ isOpen, closeModal, selectPrompt }) => {
  const guidedPrompts = [
    "What are you grateful for today?",
    "What made you happy today?",
    "What challenges did you overcome today?",
    "What did you learn today?",
    "How are you feeling right now?",
    "What are you most excited about right now?",
    "What goal do you want to achieve tomorrow?",
    "What was the best part of your day?",
    "What did you do today that you’re proud of?",
    "How did you show kindness to others today?",
    "What is one lesson you learned today?",
    "What did you do to take care of your health today?",
    "What did you do to relax and recharge?",
    "What was the most challenging part of your day?",
    "What was a moment of joy today?",
    "What did you learn about yourself today?",
    "How can you make tomorrow even better?",
    "How did today align with your personal values?"
  ];

  const [selectedPrompt, setSelectedPrompt] = useState(guidedPrompts[0]); // Default to first guided prompt

  const handlePromptSelection = (promptType) => {
    if (promptType === "personalized") {
      selectPrompt("personalized"); // Pass a signal for personalized prompt generation
    } else {
      selectPrompt(selectedPrompt); // Use the selected guided prompt
    }
    closeModal(); // Close the modal after selecting
  };
  

  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={closeModal}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="goal-accordion-container">
          <h3>Select a Guided Prompt</h3>
          <CancelIcon onClick={closeModal} className="cancel-icon" />
        </div>
        
        {/* Dropdown for guided prompts */}
        <div className="guided-prompt-dropdown">
          <label htmlFor="guided-prompts"></label>
          <select
            id="guided-prompts"
            value={selectedPrompt}
            onChange={(e) => setSelectedPrompt(e.target.value)}
            className="guided-prompt-select"
          >
            {guidedPrompts.map((prompt, index) => (
              <option key={index} value={prompt}>
                {prompt}
              </option>
            ))}
          </select>
        </div>
        <div className='prompt-button-container'>
            {/* Button to confirm the selection of a guided prompt */}
            <button className="select-guided-prompt-btn" onClick={handlePromptSelection}>
            Use Selected Prompt
            </button>

            {/* Button for personalized prompt */}
            <button className="personalized-prompt" onClick={() => handlePromptSelection("personalized")}>
            Get Personalized Prompt
            </button>
        </div>
      </div>
    </div>
  );
};

export default GuidedPromptModal;
