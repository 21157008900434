import React, { useState, useEffect } from 'react';
import GoalModal from '../GoalModal/GoalModal'; // Reusing GoalModal
import { supabase } from '../../supabaseClient'; // Import Supabase client
import { ExpandIcon } from '../../assets/icons/icons'; // Import ExpandIcon
import './style.css'; // CSS for styling

const MyGoals = () => {
  const [activeGoals, setActiveGoals] = useState([]);
  const [completedGoals, setCompletedGoals] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal state
  const [selectedGoal, setSelectedGoal] = useState(null); // For editing a goal
  const [activeOpen, setActiveOpen] = useState(true); // Accordion state for Active Goals
  const [completedOpen, setCompletedOpen] = useState(false); // Accordion state for Completed Goals

  // Fetch goals when the component mounts
  useEffect(() => {
    const fetchGoals = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session) return;

      const userId = session.user.id;

      // Fetch active goals (status: true)
      const { data: activeData, error: activeError } = await supabase
        .from('goals')
        .select('*')
        .eq('userid', userId)
        .eq('status', true)
        .eq('archive', false);

      // Fetch completed goals (status: false)
      const { data: completedData, error: completedError } = await supabase
        .from('goals')
        .select('*')
        .eq('userid', userId)
        .eq('status', false)
        .eq('archive', false);

      if (activeError || completedError) {
        console.error('Error fetching goals:', activeError || completedError);
      } else {
        setActiveGoals(activeData || []);
        setCompletedGoals(completedData || []);
      }
    };

    fetchGoals();
  }, []);

  // Handle opening the modal for adding a new goal
  const handleAddGoal = () => {
    setSelectedGoal(null); // Ensure no goal is selected for a new goal
    setIsModalOpen(true);
  };

  // Handle clicking on a goal to view/edit it in the modal
  const handleGoalClick = (goal) => {
    setSelectedGoal(goal);
    setIsModalOpen(true);
  };

  // Handle saving or updating a goal
  const handleSaveGoal = async (goal) => {
    const { data: { session } } = await supabase.auth.getSession();
    if (!session) return;

    const userId = session.user.id;

    if (selectedGoal) {
      // Update existing goal
      const { data, error } = await supabase
        .from('goals')
        .update({ ...goal, userid: userId })
        .eq('id', selectedGoal.id)
        .select();

      if (error) {
        console.error('Error updating goal:', error);
      } else if (data && data.length > 0) {
        setActiveGoals((prev) => prev.map((g) => (g.id === selectedGoal.id ? data[0] : g)));
      }
    } else {
      // Insert new goal
      const { data, error } = await supabase
        .from('goals')
        .insert([{ ...goal, userid: userId, status: true, createdat: new Date() }])
        .select();

      if (error) {
        console.error('Error adding goal:', error);
      } else if (data && data.length > 0) {
        setActiveGoals((prev) => [...prev, data[0]]);
      }
    }

    setIsModalOpen(false); // Close modal after saving
  };

  const markGoalAsCompleted = async (goalId) => {
    const completedAt = new Date(); // Get current date and time
    const { data, error } = await supabase
      .from('goals')
      .update({ status: false, completedat: completedAt }) // Set status to false and add completedat timestamp
      .eq('id', goalId)
      .select();
  
    if (error) {
      console.error('Error marking goal as completed:', error);
    } else if (data && data.length > 0) {
      const completedGoal = data[0];
      setActiveGoals((prev) => prev.filter((goal) => goal.id !== goalId)); // Remove from active goals
      setCompletedGoals((prev) => [...prev, completedGoal]); // Add to completed goals
    }
  };  

  // Handle re-activating a goal from the completed list
  const handleReActivateGoal = async (goal) => {
    const { error } = await supabase
      .from('goals')
      .update({ status: true })
      .eq('id', goal.id)
      .select();

    if (error) {
      console.error('Error re-activating goal:', error);
    } else {
      setCompletedGoals((prev) => prev.filter((g) => g.id !== goal.id));
      setActiveGoals((prev) => [...prev, goal]); // Add back to active goals
    }

    setIsModalOpen(false);
  };

  return (
    <div className="my-goals-container">
        <div className='write-content'>
            <h1 className='my-goals-title'>My Goals</h1>

            {/* Active Goals Accordion */}
            <div className="accordion-section">
            <div className="accordion-header" onClick={() => setActiveOpen(!activeOpen)}>
                <h3 className='my-goals-accordion-header'>Active Goals</h3>
                <ExpandIcon className={activeOpen ? 'close-icon' : 'open-icon'} />
            </div>
            {activeOpen && (
                <div className="accordion-content scrollable-list">
                {activeGoals.length > 0 ? (
                    <ul>
                    {activeGoals.map((goal) => (
                        <li key={goal.id} onClick={() => handleGoalClick(goal)} className='my-goal-item'>
                        <span>{goal.title}</span>
                        </li>
                    ))}
                    </ul>
                ) : (
                    <p>No active goals</p>
                )}
                </div>
            )}
            </div>

            {/* Completed Goals Accordion */}
            <div className="accordion-section">
            <div className="accordion-header" onClick={() => setCompletedOpen(!completedOpen)}>
                <h3 className='my-goals-accordion-header'>Completed Goals</h3>
                <ExpandIcon className={completedOpen ? 'close-icon' : 'open-icon'} />
            </div>
            {completedOpen && (
                <div className="accordion-content scrollable-list">
                {completedGoals.length > 0 ? (
                    <ul>
                    {completedGoals.map((goal) => (
                        <li key={goal.id} onClick={() => handleGoalClick(goal)} className='my-goal-item'>
                        <span>{goal.title}</span>
                        <span className="completed-date">
                            {new Date(goal.completedat).toLocaleDateString()}
                        </span>
                        </li>
                    ))}
                    </ul>
                ) : (
                    <p>No completed goals</p>
                )}
                </div>
            )}
            </div>

            {/* Button to add a new goal */}
            <button className="add-goal-btn" onClick={handleAddGoal}>
            Add Goal
            </button>

            {/* Goal Modal for adding/editing goals */}
            <GoalModal
            isOpen={isModalOpen}
            closeModal={() => setIsModalOpen(false)}
            addGoal={handleSaveGoal}
            updateGoal={handleReActivateGoal} // Handles re-activation for completed goals
            markComplete={markGoalAsCompleted} // Pass markGoalAsCompleted to the modal
            selectedGoal={selectedGoal}
            />
        </div>
    </div>
  );
};

export default MyGoals;
