// src/pages/GoalsPage.js
import React from "react";
import MyGoals from "../components/MyGoals/MyGoals";
import NavBar from "../components/NavBar/NavBar";

export default function EntryPage() {
  return (
    <>
      <NavBar/>
      <main className="web-view">
        <MyGoals/>
      </main>
    </>
  );
}