// src/pages/Entries.js
import React from "react";
import Login from "../components/Login/Login";

export default function LoginPage() {
  return (
    <>
      <main className="web-view">
        <Login/>
      </main>
    </>
  );
}
