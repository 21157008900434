// src/pages/Entries.js
import React from "react";
import TermsOfService from "../components/TermsOfService/TermsOfService";

export default function TOSPage() {
  return (
    <>
      <main className="web-view">
        <TermsOfService/>
      </main>
    </>
  );
}
