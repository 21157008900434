// src/pages/Entries.js
import React from "react";
import PolicyPrivacy from "../components/PrivacyPolicy/PrivacyPolicy";

export default function PrivacyPage() {
  return (
    <>
      <main className="web-view">
        <PolicyPrivacy/>
      </main>
    </>
  );
}
