import React, { useState } from "react";
import { supabase } from '../../supabaseClient'; // Import Supabase client
import LoadingAnimation from '../LoadingAnimation/LoadingAnimation'; // Import the LoadingAnimation component
import GuidedPromptModal from "../GuidedPromptModal/GuidedPromptModal"; // Import the GuidedPromptModal component
import './style.css';

export default function Write(props) {
    const [title, setTitle] = useState('');
    const [body, setBody] = useState('');
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [affirmation, setAffirmation] = useState('');
    const [isLoading, setIsLoading] = useState(false); // Loading state for AI
    const [isModalOpen, setIsModalOpen] = useState(false); // State for the modal
    const [selectedPrompt, setSelectedPrompt] = useState('What\'s on your mind?'); // Default prompt

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    // Function to handle prompt selection from modal
    const handlePromptSelection = async (prompt) => {
        if (prompt === "personalized") {
            const personalizedPrompt = await generatePersonalizedPrompt(); // Generate personalized prompt
            setSelectedPrompt(personalizedPrompt); // Set personalized prompt as the selected prompt
        } else {
            setSelectedPrompt(prompt); // Set selected guided prompt
        }
    };

    const generatePersonalizedPrompt = async () => {
        const { data: { session } } = await supabase.auth.getSession();
        if (!session) return;

        const userId = session.user.id;

        // Calculate the date for past week and format it using toISOString()
        const pastWeekDate = new Date(new Date().setDate(new Date().getDate() - 7)).toISOString();

        const { data: entries, error } = await supabase
            .from('entries')
            .select('entry_reflection')
            .eq('userid', userId)
            .gte('createdat', pastWeekDate);

        if (error) {
            console.error('Error fetching entries:', error);
            return '';
        }

        if (!entries || entries.length === 0) {
            // console.log('No entries found for the past week');
            return 'No recent reflections found to create a personalized prompt.';
        }

        // Construct reflections string
        const reflections = entries.map(entry => entry.entry_reflection).join("\n");

        // Call the AI API to generate a personalized prompt based on reflections
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/generate-personalized-prompt`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ reflections }) // Ensure the reflections string is sent
        });

        const data = await response.json();
        return data.personalizedPrompt; // Return the generated personalized prompt
    };

    // Function to fetch AI responses from your server-side
    const generateAIResponses = async (entryText) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/generate-reflection`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ entryText })
            });

            if (!response.ok) {
                throw new Error('Failed to fetch AI response');
            }

            // Parse the JSON response to get the reflection, insight, and affirmation
            const data = await response.json();
            return data;  // Returning the entire object { entryReflection, keyInsight, affirmation }
        } catch (error) {
            console.error('Error fetching AI response:', error);
            throw error;
        }
    };

    // Streak check functionality
    const updateStreak = async () => {
        const { data: { session } } = await supabase.auth.getSession();
        if (!session) return;
    
        const userId = session.user.id;
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Set the time to the start of today
    
        try {
            // Fetch the current streak and last_updated timestamp
            const { data: userData, error: userError } = await supabase
                .from('users')
                .select('streak, last_updated')
                .eq('id', userId)
                .single();
    
            if (userError || !userData) {
                console.error('Error fetching user data:', userError);
                return;
            }
    
            const lastUpdated = new Date(userData.last_updated);
            
            // Check if the user has already submitted an entry or reflection today
            const { data: entriesToday, error: entriesError } = await supabase
                .from('entries')
                .select('*')
                .eq('userid', userId)
                .gte('createdat', today.toISOString());
    
            const { data: reflectionsToday, error: reflectionsError } = await supabase
                .from('reflections')
                .select('*')
                .eq('userid', userId)
                .gte('createdat', today.toISOString());
    
            if (entriesError || reflectionsError) {
                console.error('Error fetching today\'s entries or reflections:', entriesError || reflectionsError);
                return;
            }
    
            // If no entries or reflections were submitted today, update the streak
            if (entriesToday.length === 0 && reflectionsToday.length === 0 && lastUpdated < today) {
                const newStreak = userData.streak + 1;
                const { error: updateError } = await supabase
                    .from('users')
                    .update({ streak: newStreak, last_updated: new Date() })
                    .eq('id', userId);
    
                if (updateError) {
                    console.error('Error updating streak:', updateError);
                } else {
                    console.log('Streak updated successfully!');
                }
            }
        } catch (error) {
            console.error('Error updating streak:', error);
        }
    };

    // Handle form submission to add a new journal entry and update streak
    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);
        setSuccessMessage(null); // Reset messages
        setIsLoading(true); // Start loading

        try {
            const { data: { session } } = await supabase.auth.getSession();
            if (!session) {
                throw new Error('User is not authenticated');
            }

            const userId = session.user.id;

            // Call the backend to generate the AI response
            const aiResponse = await generateAIResponses(body);

            // Extract AI-generated data (reflection, insight, and affirmation)
            const { entryReflection, keyInsight, affirmation: affirmationText } = aiResponse;

            // Insert new entry into Supabase
            const { error } = await supabase
                .from('entries')
                .insert([
                    {
                        userid: userId,
                        title: title || 'Untitled', 
                        body: body,
                        prompt: selectedPrompt, // Store the selected prompt in the database
                        createdat: new Date(),
                        entry_reflection: entryReflection,
                        key_insight: keyInsight,
                        affirmation: affirmationText,
                    },
                ]);

            if (error) {
                throw error;
            }

            // Update the user's streak
            await updateStreak();

            // Clear the form on successful submission
            setTitle('');
            setBody('');
            setAffirmation(affirmationText); // Show the generated affirmation
            setSuccessMessage('Entry successfully added!');
            setIsLoading(false); // Stop loading after submission

        } catch (error) {
            console.error('Error submitting entry:', error);
            setError('Failed to submit entry. Please try again.');
            setIsLoading(false); // Stop loading in case of an error
        }
    };

    return (
        <>
            <div className="write-section">
                <div className="write-content">
                    {isLoading ? (
                        <div className="animation-container">
                            <LoadingAnimation />
                        </div>
                    ) : (
                        <>
                            {affirmation ? (
                                <>
                                    <div className="affirmation-section">
                                        <h3>Your Affirmation</h3>
                                        <p>{affirmation}</p>
                                    </div>
                                    <button onClick={(e) => window.location.href='/'}>Close</button>
                                    {successMessage && <p className="success-message">{successMessage}</p>}
                                </>
                            ) : (
                                <>
                                    <div className="write-accordion-container">
                                        {/* Display selected or default prompt */}
                                        <h3>{selectedPrompt}</h3>
                                        <button onClick={openModal}>Guided Prompt</button> {/* Button to open modal */}
                                    </div>
                                    <form onSubmit={handleSubmit}>
                                        <input
                                            className="journal-title"
                                            type="text"
                                            placeholder="Title (optional)"
                                            value={title}
                                            onChange={(e) => setTitle(e.target.value)}
                                        />
                                        <label>
                                            <textarea
                                                className='journal-textarea'
                                                placeholder="Write..."
                                                value={body}
                                                onChange={(e) => setBody(e.target.value)}
                                                required
                                            />
                                        </label>
                                        <button type="submit">
                                            Finish
                                        </button>
                                    </form>
                                </>
                            )}
                        </>
                    )}
                </div>
            </div>

            {/* Modal for selecting a guided prompt */}
            <GuidedPromptModal isOpen={isModalOpen} closeModal={closeModal} selectPrompt={handlePromptSelection} selectPersonalized={generatePersonalizedPrompt}/>
        </>
    );
}
