import React, { useState, useEffect } from 'react';
import { CancelIcon } from '../../assets/icons/icons';
import './style.css';

const GoalModal = ({ isOpen, closeModal, addGoal, updateGoal, selectedGoal, markComplete }) => {
  const [goalName, setGoalName] = useState('');
  const [frequency, setFrequency] = useState('Once');
  const [description, setDescription] = useState('');

  // Reset fields when modal opens or selectedGoal changes
  useEffect(() => {
    if (isOpen && !selectedGoal) {
      // Clear fields when adding a new goal
      setGoalName('');
      setFrequency('Once');
      setDescription('');
    } else if (selectedGoal) {
      // Populate the modal with the goal's existing data when editing
      setGoalName(selectedGoal.title);
      setFrequency(selectedGoal.frequency);
      setDescription(selectedGoal.description);
    }
  }, [isOpen, selectedGoal]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (selectedGoal) {
      if (selectedGoal.status === false) {
        // If the goal is completed, we are re-activating it
        updateGoal({ ...selectedGoal, status: true });
      } else {
        // Update existing goal
        updateGoal({ id: selectedGoal.id, title: goalName, frequency: frequency, description: description });
      }
    } else {
      // Add a new goal
      addGoal({ title: goalName, frequency: frequency, description: description });
    }

    closeModal(); // Close the modal after submission
  };

  const handleMarkAsComplete = () => {
    if (selectedGoal) {
      // Mark the goal as complete by calling the markComplete function
      markComplete(selectedGoal.id); 
      closeModal(); // Close the modal after marking as complete
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={closeModal}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="goal-accordion-container">
          <h3>{selectedGoal ? 'Edit Goal' : 'Add to Goal Tracker'}</h3>
          <CancelIcon onClick={closeModal} className='cancel-icon'/>
        </div>
        <form onSubmit={handleSubmit}>
          <label>
            Name
            <input
              className='goal-tracker-input'
              type="text"
              placeholder="A task, habit, or goal..."
              value={goalName}
              onChange={(e) => setGoalName(e.target.value)}
              required
            />
          </label>
          <label>
            Frequency
            <select
              value={frequency}
              onChange={(e) => setFrequency(e.target.value)}
            >
              <option value="Once">Once</option>
              <option value="Daily">Daily</option>
              <option value="Weekly">Weekly</option>
              <option value="Monthly">Monthly</option>
              <option value="Forever">Forever</option>
            </select>
          </label>
          <label>
            Description
            <textarea
              placeholder="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className='extended-textarea'
            />
          </label>
          {selectedGoal ? (
            <div className='goal-btn-container'>
              <button type="submit">{selectedGoal.status === false ? 'Re-Activate Goal' : 'Update Goal'}</button>
              {selectedGoal.status !== false && (
                <button
                  className='goal-complete'
                  type="button"
                  onClick={handleMarkAsComplete} // Call the function to mark as complete
                >
                  Mark as complete
                </button>
              )}
            </div>
          ) : (
            <button type="submit">Add Goal</button>
          )}
        </form>
      </div>
    </div>
  );
};

export default GoalModal;
