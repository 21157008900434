import React from "react";
import NavBar from "../components/NavBar/NavBar";
import Write from "../components/Write/Write";

export default function WritePage(props) {
  return (
    <>
        <NavBar/>
        <main className="web-view">
          <Write/>
        </main>
    </>
  );
};
