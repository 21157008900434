import React, { useState, useEffect } from "react";
import ReflectionModal from "../ReflectionModal/ReflectionModal";
import MorningArt from '../../assets/svgs/redhair-boy.svg';
import MidDayArt from '../../assets/svgs/balloon.svg';
import EveningArt from '../../assets/svgs/redhair-girl.svg';
import './style.css';
import { supabase } from '../../supabaseClient'; // Import Supabase client
import { CheckIcon } from '../../assets/icons/icons'; // Import the CheckIcon

// Define the different prompts and images based on the time of day
const getReflectionType = () => {
    const currentHour = new Date().getHours();

    if (currentHour >= 5 && currentHour < 12) {
        return {
            type: 'morning',
            prompt: 'What do you want to accomplish today? Start your day on the right foot with intention.',
            image: MorningArt,
            title: 'Morning Intention',
            button: 'Start Intention'
        };
    } else if (currentHour >= 12 && currentHour < 18) {
        return {
            type: 'noon',
            prompt: 'How are you doing so far? Take a quick moment to take a pulse check on the day.',
            image: MidDayArt,
            title: 'Mid-Day Check-in',
            button: 'Check in now'
        };
    } else {
        return {
            type: 'evening',
            prompt: 'How was your day? Take a moment to reflect.',
            image: EveningArt,
            title: 'Daily Reflection',
            button: 'Start Reflection'
        };
    }
};

export default function DailyReflection() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [reflectionType, setReflectionType] = useState({ type: '', prompt: '', image: '', title: '', button: '' });
    const [isCompleted, setIsCompleted] = useState(false); // Track whether reflection is completed

    useEffect(() => {
        const reflection = getReflectionType();
        setReflectionType(reflection);
        checkReflectionCompletion(reflection.type); // Check if the reflection for the current time is completed
    }, []);

    // Function to check if the reflection is already completed for the current type and date
    const checkReflectionCompletion = async (reflectionType) => {
        const { data: { session } } = await supabase.auth.getSession();
        if (!session) return;

        const userId = session.user.id;
        const today = new Date().toISOString().split('T')[0]; // Get today's date

        const { data, error } = await supabase
            .from('reflections')
            .select('*')
            .eq('userid', userId)
            .eq('type', reflectionType)
            .gte('createdat', today);

        if (error) {
            console.error('Error fetching reflections:', error);
        } else if (data.length > 0) {
            setIsCompleted(true); // Reflection for this time of day is completed
        } else {
            setIsCompleted(false); // No reflection for this time of day
        }
    };

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    const handleReflectionSubmit = () => {
        setIsCompleted(true); // Mark the reflection as completed after submission
        toggleModal(); // Close the modal
        window.location.href='/'
    };

    return (
        <div className="journal-entry">
            <h3>{reflectionType.title}</h3>
            {isCompleted ? (
                <>
                    <div className="reflection-art-container">
                        <div className="completed-state">
                            <CheckIcon className="completed-icon" />
                            <span>Completed</span>
                        </div>
                        <img src={reflectionType.image} className="reflection-art" alt="Reflection Art" />
                    </div>
                </>
            ) : (
                <>
                    <p>{reflectionType.prompt}</p>
                    <div className="reflection-art-container">
                        <button onClick={toggleModal}>{reflectionType.button}</button>
                        <img src={reflectionType.image} className="reflection-art" alt="Reflection Art" />
                    </div>
                </>
            )}
            <ReflectionModal
                isOpen={isModalOpen}
                closeModal={toggleModal}
                reflectionType={reflectionType}
                onSubmit={handleReflectionSubmit} // Pass a callback to mark the reflection as completed
            />
        </div>
    );
}
