// src/pages/Entries.js
import React from "react";
import Entries from "../components/Entries/Entries";
import NavBar from "../components/NavBar/NavBar";

export default function EntryPage() {
  return (
    <>
      <NavBar/>
      <main className="web-view">
        <Entries/>
      </main>
    </>
  );
}
