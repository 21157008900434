import { useState, useEffect } from "react";
import { supabase } from "../supabaseClient"; // Assuming this is where you have the Supabase client
import GoalTracker from "../components/GoalTracker/GoalTracker";
import CalmLeafChatbot from "../components/CalmLeafChatbot/CalmLeafChatbot";
import DailyReflection from "../components/DailyReflection/DailyReflection";
import DayTracker from "../components/DayTracker/DayTracker";
import NavBar from "../components/NavBar/NavBar";

export default function Dashboard() {
  const [journalEntries, setJournalEntries] = useState([]);
  const [isSideNav, setIsSideNav] = useState(true);
  const [userId, setUserId] = useState(null); // State for the user ID

  useEffect(() => {
    // Fetch user ID from Supabase session
    const fetchUserId = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      if (session) {
        setUserId(session.user.id); // Set userId to the state
      }
    };

    fetchUserId();
  }, []);

  const addJournalEntry = (entry) => {
    setJournalEntries([...journalEntries, entry]);
  };

  return (
    <>
      <NavBar />
      <main className="web-view">
        <div className="dashboard">
          {/* Add DayTracker component at the top and make sure it spans the width */}
          <div className="date-section">
            <DayTracker journalEntries={journalEntries} />
          </div>

          <div className="reflection-section">
            <DailyReflection onAddEntry={addJournalEntry} />
          </div>
          <div className="dashboard-section">
            <GoalTracker />
          </div>
          {/* Pass userId to CalmLeafChatbot */}
          {userId && <CalmLeafChatbot userId={userId} entries={journalEntries} />}
        </div>
      </main>
    </>
  );
}
