import React, { useEffect, useState } from 'react';
import { supabase } from '../../supabaseClient'; // Import Supabase client
import { AnalyzeIcon, NoteIcon, SearchIcon, MaximizeIcon, MinimizeIcon } from '../../assets/icons/icons';
import './style.css'; // Assuming we will style the page here

export default function Entries(props) {
    const [entries, setEntries] = useState([]);
    const [selectedEntry, setSelectedEntry] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredEntries, setFilteredEntries] = useState([]);
    const [error, setError] = useState(null);
    const [activeTab, setActiveTab] = useState('entries'); // Tab selector state
    const [activeTabDetails, setActiveTabDetails] = useState('entries'); // Tab selector state
    const [isFullscreen, setIsFullscreen] = useState(false); // Fullscreen mode state

    // Fetch entries or reflections depending on active tab
    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data: { session } } = await supabase.auth.getSession();
                if (!session) throw new Error('User is not authenticated');
                const userId = session.user.id;

                let response;
                setSelectedEntry(null); // Clear selected entry when switching tabs
                setFilteredEntries([]); // Clear filtered entries when switching tabs

                if (activeTab === 'entries') {
                    response = await supabase
                        .from('entries')
                        .select('*')
                        .eq('userid', userId)
                        .order('createdat', { ascending: false });
                } else if (activeTab === 'reflections') {
                    response = await supabase
                        .from('reflections')
                        .select('*')
                        .eq('userid', userId)
                        .order('createdat', { ascending: false });
                }

                if (response.error) throw response.error;
                const data = response.data || [];
                setEntries(data);
                setFilteredEntries(data);
            } catch (error) {
                console.error('Error fetching data:', error);
                setError('Failed to fetch data');
            }
        };

        fetchData();
    }, [activeTab]);

    // Filter entries/reflections based on search term
    useEffect(() => {
        const filtered = entries.filter(entry =>
            entry.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
            entry.body.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredEntries(filtered);
    }, [searchTerm, entries]);

    const handleEntryClick = (entry) => {
        setSelectedEntry(entry);
    };

    const formatDate = (dateString) => {
        const currentDate = new Date();
        const entryDate = new Date(dateString);
        const diffInTime = currentDate.getTime() - entryDate.getTime();
        const diffInDays = diffInTime / (1000 * 3600 * 24);

        if (diffInDays <= 7) {
            return entryDate.toLocaleDateString('en-US', { weekday: 'long' }).toUpperCase();
        } else {
            return entryDate.toLocaleDateString('en-GB');
        }
    };

    const getOrdinalSuffix = (day) => {
        if (day > 3 && day < 21) return 'th'; 
        switch (day % 10) {
            case 1: return 'st';
            case 2: return 'nd';
            case 3: return 'rd';
            default: return 'th';
        }
    };

    const formatDateTime = (dateString) => {
        const date = new Date(dateString);
        const dayOfWeek = date.toLocaleDateString('en-US', { weekday: 'long' });
        const month = date.toLocaleDateString('en-US', { month: 'long' });
        const day = date.getDate();
        const dayWithSuffix = `${day}${getOrdinalSuffix(day)}`;
        const time = date.toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        }).toLowerCase();

        return `${dayOfWeek}, ${month} ${dayWithSuffix} @ ${time}`;
    };

    const renderReflectionDetails = () => {
        if (selectedEntry && selectedEntry.question && selectedEntry.body) {
            const questions = selectedEntry.question.split(" | ");
            const answers = selectedEntry.body.split("|");

            return (
                <div className="reflection-details">
                    {questions.map((question, index) => (
                        <div key={index} className="reflection-question-answer">
                            <p className="question">{question}</p>
                            <p className="answer">{answers[index] || "No answer provided"}</p>
                        </div>
                    ))}
                </div>
            );
        } else {
            return <p>No reflection details available.</p>;
        }
    };

    const renderAIInsights = () => {
        if (selectedEntry) {
            return (
                <div className="ai-insights">
                    <div className="insights-container">
                        <p className='insight-title'>Entry Reflection</p>
                        <p className='insight-text'>{selectedEntry.entry_reflection || "No reflection available"}</p>
                    </div>
                    <div className="insights-container">
                        <p className='insight-title'>Key Insight</p>
                        <p className='insight-text'>{selectedEntry.key_insight || "No key insight available"}</p>
                    </div>
                    <div className="insights-container">
                        <p className='insight-title'>Affirmation</p>
                        <p className='insight-text'>{selectedEntry.affirmation || "No affirmation available"}</p>
                    </div>
                </div>
            );
        }
        return null;
    };

    // Toggle fullscreen mode
    const toggleFullscreen = () => {
        setIsFullscreen(!isFullscreen);
    };

    return (
        <div className="entries-page">
            <div className="sidebar">
                <div className="tab-selector">
                    <h1
                        className={`tab-item ${activeTab === 'entries' ? 'tab-item-active' : ''}`}
                        onClick={() => setActiveTab('entries')}
                    >
                        Entries
                    </h1>
                    <h1
                        className={`tab-item ${activeTab === 'entries' ? '' : 'tab-item-active'}`}
                        onClick={() => setActiveTab('reflections')}
                    >
                        Reflections
                    </h1>
                </div>

                <div className='search-container'>
                    <input
                        type="text"
                        placeholder="Search"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <SearchIcon className='search-icon' />
                </div>

                <div className="entry-list">
                    {filteredEntries.length > 0 ? (
                        filteredEntries.map((entry) => (
                            <div
                                key={entry.id}
                                className={`entry-item ${selectedEntry?.id === entry.id ? 'active' : ''}`}
                                onClick={() => handleEntryClick(entry)}
                            >
                                <p className='entry-title'>{entry.title}</p>
                                <small>{formatDate(entry.createdat)}</small>
                            </div>
                        ))
                    ) : (
                        <p className='entry-error-msg'>No {activeTab === 'entries' ? 'entries' : 'reflections'} found</p>
                    )}
                </div>
            </div>

            <div className={`entry-details ${isFullscreen ? 'fullscreen' : ''}`}>
                {selectedEntry ? (
                    <>
                        <div className="entry-title-container">
                            <h2 className='entry'>{selectedEntry.title}</h2>
                            <div className='entry-icon-container'>
                                <div className="entry-tab-selector">
                                    <div className={`entry-tab-container ${activeTabDetails === 'entries' ? 'entry-tab-container-active' : ''}`} onClick={() => setActiveTabDetails('entries')}>
                                        <NoteIcon className={`entry-tab-item ${activeTabDetails === 'entries' ? 'entry-tab-item-active' : ''}`} />
                                        <span className='entry-tab-title'>Entry</span>
                                    </div>
                                    <div className={`entry-tab-container ${activeTabDetails === 'entries' ? '' : 'entry-tab-container-active'}`} onClick={() => setActiveTabDetails('analyze')}>
                                        <AnalyzeIcon className={`entry-tab-item ${activeTabDetails === 'entries' ? '' : 'entry-tab-item-active'}`} />
                                        <span className='entry-tab-title'>Insights</span>
                                    </div>
                                </div>
                                {isFullscreen ? (
                                    <MinimizeIcon className='icon' onClick={toggleFullscreen} />
                                ) : (
                                    <MaximizeIcon className='icon' onClick={toggleFullscreen} />
                                )}
                            </div>
                        </div>
                        <p>{formatDateTime(selectedEntry.createdat)}</p>
                        {activeTabDetails === 'entries' ? (
                            <>
                                {activeTab === 'entries' ? (
                                    <>
                                        <p className='entry-question'>What's on your mind?</p>
                                        <div className="entry-body">
                                            <p>{selectedEntry.body}</p>
                                        </div>
                                    </>
                                ) : (
                                    renderReflectionDetails()
                                )}
                            </>
                        ) : (
                            <>
                                {renderAIInsights()}
                            </>
                        )}
                    </>
                ) : (
                    <p>Select an {activeTab === 'entries' ? 'entry' : 'reflection'} to view details</p>
                )}
            </div>
        </div>
    );
}
