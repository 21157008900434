import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for redirection
import { supabase } from '../../supabaseClient'; // Import the Supabase client
import { signUp, logIn, signInWithGoogle } from '../../auth'; // Import signUp and logIn functions
import './style.css'; // We'll add styles in Login.css
import { GoogleLogo, EyeIcon, EyeSlashIcon } from '../../assets/icons/icons'; // Import your Eye icons

export default function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const [isSigningUp, setIsSigningUp] = useState(false); // Toggle between sign-in and sign-up
    const [showPassword, setShowPassword] = useState(false); // State for toggling password visibility
    const [accountCreated, setAccountCreated] = useState(false); // State for showing account created message

    const navigate = useNavigate(); // For redirection

    // Handle Email Sign-In or Sign-Up
    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null); // Reset error message

        try {
            if (isSigningUp) {
                // If signing up, create a new account
                const user = await signUp(email, password);
                // console.log('User signed up:', user);
                setAccountCreated(true); // Show confirmation message
            } else {
                // If logging in, proceed with login and redirect
                const { user } = await logIn(email, password);
                // console.log('User signed in:', user);
                navigate('/'); // Redirect to dashboard after login
            }
        } catch (error) {
            setError(error.message);
        }
    };

    // Handle Google Sign-In
    const handleGoogleLogin = async () => {
        try {
            await signInWithGoogle();
            navigate('/dashboard'); // Redirect after successful Google login
        } catch (error) {
            setError(error.message);
        }
    };

    // Toggle password visibility
    const togglePasswordVisibility = () => {
        setShowPassword((prevState) => !prevState);
    };

    return (
        <div className="write-section">
            <div className="modal-content">
                <h1>{isSigningUp ? `Create your account` : 'Login to CalmLeaf'}</h1>
                {error && <p className="error-message">{error}</p>}
                
                {/* Show message for account confirmation */}
                {isSigningUp && accountCreated && (
                    <p className="success-message">Please check your email for a confirmation link to complete signup.</p>
                )}

                <form onSubmit={handleSubmit}>
                    <label>Email:</label>
                    <input
                        type="email"
                        value={email}
                        id="email"
                        name="email"
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Email address"
                        autoComplete="email"
                        required
                        className='login-input'
                    />

                    <label>Password:</label>
                    <div className="password-input-container">
                        <input
                            type={showPassword ? 'text' : 'password'}
                            value={password}
                            id="password"
                            name="password"
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Password"
                            autoComplete={isSigningUp ? 'new-password' : 'current-password'}
                            required
                            className='login-input'
                        />
                        <span className="password-toggle-icon" onClick={togglePasswordVisibility}>
                            {!showPassword ? <EyeSlashIcon /> : <EyeIcon />}
                        </span>
                    </div>

                    <button type="submit" className="login-button">
                        {isSigningUp ? 'Create free account' : 'Login'}
                    </button>
                </form>

                <button onClick={handleGoogleLogin} className="google-button">
                    <GoogleLogo className="google-icon" />
                    Continue with Google
                </button>

                <div className="toggle-auth">
                    {isSigningUp ? (
                        <p>
                            Already have an account?{' '}
                            <span onClick={() => setIsSigningUp(false)}>Sign In</span>
                        </p>
                    ) : (
                        <>
                            <p>
                                Don't have an account?{' '}
                                <span onClick={() => setIsSigningUp(true)}>Create an account</span>
                            </p>
                            <a className='login-link' href='/privacy-policy'>Privacy Policy</a> <a className='login-link' href='/terms-of-service'>Terms of Service</a>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}
