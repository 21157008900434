import { supabase } from './supabaseClient';

// Sign up function and insert user into 'users' table
export const signUp = async (email, password) => {
  const { data, error } = await supabase.auth.signUp({
    email,
    password,
  });

  if (error) {
    throw error;
  }

  const user = data.user; // Get the user from response

  // If sign up is successful, insert the user into the users table
  const { error: insertError } = await supabase
    .from('users')
    .insert([{ id: user.id, created_at: new Date() }]);

    if (insertError) {
        // console.error('Error inserting user into users table:', insertError);
      } else {
        // console.log('User inserted into users table successfully:', user.id);
      }
      

  return user;
};

// Log in function (for email/password)
export const logIn = async (email, password) => {
    const { data, error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });
  
    if (error) {
      throw error;
    }
  
    const user = data.user;
  
    // Retrieve additional user data from the 'users' table
    const { data: userData, error: fetchError } = await supabase
      .from('users')
      .select('*')
      .eq('id', user.id)
      .single(); // Ensures we expect a single row
  
    if (fetchError) {
    console.error('Error fetching user data from users table:', fetchError);
    throw fetchError;
    } else {
    // console.log('User data retrieved successfully:', userData);
    }
  
    return { user, userData };
  };

// Google Sign-In function
export const signInWithGoogle = async () => {
  const { error } = await supabase.auth.signInWithOAuth({
    provider: 'google',
  });

  if (error) {
    throw error;
  }
};
