import React, { useState, useEffect } from "react";
import './style.css';
import { MaximizeIcon, MinimizeIcon } from "../../assets/icons/icons";

const CalmLeafChatbot = ({ userId }) => {
  const [userQuestion, setUserQuestion] = useState("");
  const [chatLog, setChatLog] = useState([]); // Store chat log as an array of questions and responses
  const [timeFrame, setTimeFrame] = useState("Past-week");
  const [isFullscreen, setIsFullscreen] = useState(false); // Track fullscreen state
  const [suggestedPrompts, setSuggestedPrompts] = useState([]);

  // Full list of suggested prompts
  const allSuggestedPrompts = [
    "What drains my energy most?",
    "What fears are holding me back?",
    "What ignites my passion and creativity?",
    "What patterns repeat in my relationships?",
    "What book recommendations do you have based on my entries?",
    "What recurring themes do I notice in my journal entries?",
    "How have my emotional patterns evolved over time?",
    "What are my biggest sources of stress?",
    "How do I typically respond to challenges?",
    "What positive changes have I made in my life recently?",
    "What are the most common emotions I experience in my entries?",
    "How do I handle moments of uncertainty or doubt?",
    "What self-limiting beliefs do I frequently write about?",
    "How do my past experiences shape my present thoughts?",
    "What are my deepest values, based on what I write?",
    "What goals do I consistently mention in my entries?",
    "What coping mechanisms do I use when I’m stressed?",
    "How do I express gratitude in my journal?",
    "What patterns of thought hold me back from achieving my goals?",
    "How do I handle interpersonal conflicts?",
    "What do I fear most, and how does it impact my behavior?",
    "What aspects of my life bring me the most joy?",
    "What are the core needs I express through my writing?",
    "How do I describe myself and my self-worth?",
    "What past experiences still influence my current behavior?",
    "How do I talk about failure and success in my entries?",
    "What areas of my life seem to need the most healing?",
    "What do I value most in my relationships?",
    "How do I approach personal growth in my writing?",
    "What unresolved emotions or experiences do I notice in my journal?"
  ];

  useEffect(() => {
    const getRandomPrompts = () => {
      const shuffled = [...allSuggestedPrompts].sort(() => 0.5 - Math.random());
      return shuffled.slice(0, 5);
    };
    setSuggestedPrompts(getRandomPrompts());
  }, []);

  const handleQuestionSubmit = async () => {
    if (!userQuestion.trim()) return;

    const newEntry = { question: userQuestion, response: "Loading..." };
    setChatLog([...chatLog, newEntry]);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/generate-chat-response`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          userId: userId,
          userQuestion: userQuestion,
          timeFrame: timeFrame
        })
      });

      const data = await response.json();

      if (data.error) {
        setChatLog(prevLog => {
          const updatedLog = [...prevLog];
          updatedLog[updatedLog.length - 1].response = "Error: " + data.error;
          return updatedLog;
        });
      } else {
        setChatLog(prevLog => {
          const updatedLog = [...prevLog];
          updatedLog[updatedLog.length - 1].response = parseResponse(data.response);
          return updatedLog;
        });
      }
    } catch (error) {
      setChatLog(prevLog => {
        const updatedLog = [...prevLog];
        updatedLog[updatedLog.length - 1].response = "An error occurred while processing your question.";
        return updatedLog;
      });
      console.error("Error submitting question:", error);
    }

    setUserQuestion(""); // Clear the question input
  };

  const handlePromptClick = (prompt) => {
    setUserQuestion(prompt);
  };

  const parseResponse = (response) => {
    if (Array.isArray(response)) return response;
    if (typeof response !== "string") return <p>Invalid response format</p>;

    const paragraphs = response.split(/\n\n/);

    const renderTextWithBold = (text) => {
      const parts = text.split(/\*\*(.*?)\*\*/);
      return parts.map((part, i) =>
        i % 2 === 1 ? <strong key={i}>{part}</strong> : <span key={i}>{part}</span>
      );
    };

    return paragraphs.map((paragraph, index) => (
      <p key={index}>
        {paragraph.split(/\n/).map((line, i) => (
          <span key={i}>
            {renderTextWithBold(line)}
            {i < paragraph.split(/\n/).length - 1 && <br />}
          </span>
        ))}
      </p>
    ));
  };

  // Toggle fullscreen mode
  const toggleFullscreen = () => {
    setIsFullscreen(!isFullscreen);
  };

  return (
    <>
      <div className={`calmleaf-section ${isFullscreen ? 'fullscreen' : ''}`}>
        {/* <div className={`chatbot ${isFullscreen ? 'fullscreen' : ''}`}> */}
          <div className="chatbot-title-container">
            <h3>Ask CalmLeaf</h3>
            <div className="option-container">
              <div className="time-frame-selector">
                <label>
                  <select value={timeFrame} onChange={(e) => setTimeFrame(e.target.value)}>
                    <option value="Past-week">Past week</option>
                    <option value="Past-month">Past month</option>
                    <option value="All-time">All-time</option>
                  </select>
                </label>
              </div>
              {/* Toggle between Maximize and Minimize Icon */}
              {isFullscreen ? (
                <MinimizeIcon className='icon' onClick={toggleFullscreen} />
              ) : (
                <MaximizeIcon className='icon' onClick={toggleFullscreen} />
              )}
            </div>
          </div>
          
          {chatLog.length > 0 && (
            <div className={`chat-log-container ${isFullscreen ? 'fullscreen' : ''}`}>
              {chatLog.map((log, index) => (
                <div key={index} className="chat-log-entry">
                  <p><span className="chat-log-username">You:</span> {log.question}</p>
                  <div className="chat-log-entry-response">
                    <span className="chat-log-calmleaf">CalmLeaf: </span>
                    {parseResponse(log.response)}
                  </div>
                </div>
              ))}
            </div>
          )}

          <input
            type="text"
            placeholder="Ask a question about your emotional state..."
            value={userQuestion}
            onChange={(e) => setUserQuestion(e.target.value)}
            className="chat-input"
          />

          <div className="suggestions">
            {suggestedPrompts.map((prompt, index) => (
              <div
                key={index}
                className="suggestion-bubble"
                onClick={() => handlePromptClick(prompt)}
              >
                {prompt}
              </div>
            ))}
          </div>

          <button onClick={handleQuestionSubmit}>Ask</button>
        {/* </div> */}
      </div>
    </>
  );
};

export default CalmLeafChatbot;
