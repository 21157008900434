import React from 'react';
import './style.css';

export default function TermsOfService() {
    return (
        <div className="legal-view">
            <div className="legal-section">
                <h1 className='tos-header'>Terms of Service</h1>
                <div className='document-header-divider'>_</div>
                <div className='tos-main'>
                    <h2 className='tos-title'>
                        Acceptance of Terms
                    </h2>
                    <p className='tos-text'>
                        By accessing and using the CalmLeaf application ("the Service"), operated by CalmLeaf (”the Company”), you are agreeing to be bound by these Terms of Service. If you do not agree to these terms, please do not use the Service.
                    </p>
                    <h2 className='tos-title'>
                        Use of the Service
                    </h2>
                    <p className='tos-text'>
                        You agree to use the Service only for purposes permitted by these Terms of Service and any applicable laws, regulations, or generally accepted practices in the relevant jurisdictions. CalmLeaf reserves the right to refuse service to anyone for any reason at any time.
                    </p>
                    <h2 className='tos-title'>
                        User Content
                    </h2>
                    <p className='tos-text'>
                        As a private journaling service, we respect and protect your privacy. You retain full ownership of any intellectual property rights that you hold in the content that you create or own ("User Content"). When you upload or submit content to our Services, you give CalmLeaf permission to store and protect this content on your behalf. We will not use, host, reproduce, modify, create derivative works, communicate, publish, publicly perform, publicly display, or distribute your User Content for any other purposes.
                        To learn how we protect your privacy, read our <a href='privacy-policy'>Privacy Policy</a>
                    </p>
                    <h2 className='tos-title'>
                        Modification and Termination of Services
                    </h2>
                    <p className='tos-text'>
                        We are constantly innovating and changing our Services, and we may add or remove functionalities or features, and we may suspend or stop a Service altogether.
                    </p>
                    <h2 className='tos-title'>
                        Disclaimer
                    </h2>
                    <p className='tos-text'>
                        The Services are provided on an "as-is" basis without any warranties, either express or implied, including but not limited to warranties of merchantability, fitness for a particular purpose, or non-infringement. CalmLeaf, its affiliates, and third-party service providers disclaim all warranties to the fullest extent permitted by law.
                    </p>
                    <h2 className='tos-title'>
                        Limitation of Liability
                    </h2>
                    <p className='tos-text'>
                        You expressly understand and agree that CalmLeaf shall not be liable for any direct, indirect, incidental, special, consequential, or exemplary damages, including damages for loss of profits, goodwill, use, data or other intangible losses.
                    </p>
                    <h2 className='tos-title'>
                        Indemnity
                    </h2>
                    <p className='tos-text'>
                        You agree to indemnify and hold harmless CalmLeaf and its affiliates, officers, directors, employees, agents, and third-party service providers from any losses, liabilities, damages, claims, and expenses, including legal fees, arising out of or related to your use of the Services or any violation of these Terms.
                    </p>
                    <h2 className='tos-title'>
                        Privacy and Personal Information
                    </h2>
                    <p className='tos-text'>
                        For information about CalmLeaf’s data protection practices, please read our Privacy PolicyPrivacy Policy. This policy explains how we treat your personal information and protect your privacy when you use our Services.
                    </p>
                    <h2 className='tos-title'>
                        Notice for California Users
                    </h2>
                    <p className='tos-text'>
                        Under California Civil Code Section 1789.3, users of the Service from California are entitled to the following consumer rights notice: The Complaint Assistance Unit of the Division of Consumer Services of the California Department of Consumer Affairs may be contacted in writing at 1625 North Market Blvd., Suite N 112, Sacramento, CA 95834, or by telephone at (916) 445-1254 or (916) 928-1227. CalmLeaf can be contacted at support@calmleaf.app.
                    </p>
                    <h2 className='tos-title'>
                        Changes to these Terms
                    </h2>
                    <p className='tos-text'>
                        We may modify these terms or any additional terms that apply to a Service to, for example, reflect changes to the law or changes to our Services.
                        Please review these Terms of Service regularly. Your continued use of the Services after the effectiveness of such changes will constitute acceptance of and agreement to any such changes.
                    </p>
                    <h2 className='tos-title'>
                        Contact us
                    </h2>
                    <p className='tos-text'>
                        If you have any questions about these terms, you can reach us at support@calmleaf.app.
                    </p>
                    <a href='/login'>Back to login</a>
                </div>
            </div>
        </div>
    );
}
