import React, { useState, useEffect } from 'react';
import './style.css';
import { CheckIconFilled } from '../../assets/icons/icons'; // Import your filled check icon
import { supabase } from '../../supabaseClient'; // Import Supabase client

const DayTracker = () => {
  const [currentDate] = useState(new Date()); // Static current date to avoid triggering re-renders
  const [streak, setStreak] = useState(0);
  const [daysWithEntries, setDaysWithEntries] = useState(new Set());

  const daysOfWeek = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
  const todayIndex = currentDate.getDay(); // Get the index of today (0 for Sunday, 6 for Saturday)

  // Function to add ordinal suffix to the day
  const getOrdinalSuffix = (day) => {
    if (day > 3 && day < 21) return 'th'; // Catch exceptions like 11th, 12th, 13th
    switch (day % 10) {
      case 1: return 'st';
      case 2: return 'nd';
      case 3: return 'rd';
      default: return 'th';
    }
  };

  // Format the date as "Friday, Sep 6th"
  const formattedDate = `${currentDate.toLocaleDateString('en-US', { weekday: 'long' })}, ${currentDate.toLocaleDateString('en-US', { month: 'short' })} ${currentDate.getDate()}${getOrdinalSuffix(currentDate.getDate())}`;

  // Fetch journal entries and reflections for the current week and check for today's entries/reflections
  useEffect(() => {
    const fetchData = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session) {
        // console.log('No user session found');
        return;
      }

      const userId = session.user.id;

      // Get the current week (Sunday to Saturday)
      const currentWeekStart = new Date(currentDate);
      currentWeekStart.setDate(currentWeekStart.getDate() - currentWeekStart.getDay()); // Set to the most recent Sunday
      currentWeekStart.setHours(0, 0, 0, 0); // Start of the week

      const currentWeekEnd = new Date(currentWeekStart);
      currentWeekEnd.setDate(currentWeekStart.getDate() + 6); // Set to the upcoming Saturday
      currentWeekEnd.setHours(23, 59, 59, 999); // End of the week

      // console.log('Fetching entries and reflections for week:', currentWeekStart, 'to', currentWeekEnd);

      // Fetch entries for the current week
      const { data: entriesData, error: entriesError } = await supabase
        .from('entries')
        .select('*')
        .eq('userid', userId)
        .gte('createdat', currentWeekStart.toISOString())
        .lte('createdat', currentWeekEnd.toISOString());

      if (entriesError) {
        console.error('Error fetching entries:', entriesError);
      } else {
        // console.log('Fetched entries:', entriesData);
      }

      // Fetch reflections for the current week
      const { data: reflectionsData, error: reflectionsError } = await supabase
        .from('reflections')
        .select('*')
        .eq('userid', userId)
        .gte('createdat', currentWeekStart.toISOString())
        .lte('createdat', currentWeekEnd.toISOString());

      if (reflectionsError) {
        console.error('Error fetching reflections:', reflectionsError);
      } else {
        // console.log('Fetched reflections:', reflectionsData);
      }

      const combinedData = [...(entriesData || []), ...(reflectionsData || [])];

      if (combinedData.length === 0) {
        // console.log('No entries or reflections found for this week');
      } else {
        // console.log('Combined data (entries + reflections):', combinedData);
      }

      // Create a set of the day indices that have either an entry or a reflection
      const entriesByDay = combinedData.reduce((acc, entry) => {
        if (entry.createdat) { // Ensure createdat exists
          const entryDate = new Date(entry.createdat);
          if (!isNaN(entryDate)) { // Ensure the date is valid
            const dayIndex = entryDate.getDay(); // Get day index (0 for Sunday, 6 for Saturday)
            acc.add(dayIndex); // Add the day index to a Set (so it's unique)
          }
        }
        return acc;
      }, new Set());

      // console.log('Days with entries/reflections:', [...entriesByDay]);

      setDaysWithEntries(entriesByDay); // Save the Set of days with entries/reflections
    };

    fetchData();
  }, []); // Run only once on mount

  // Check if the user has submitted an entry/reflection within the last 48 hours on page load
  useEffect(() => {
    const checkStreak = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session) {
        // console.log('No user session found for streak check');
        return;
      }

      const userId = session.user.id;
      const last48Hours = new Date();
      last48Hours.setDate(currentDate.getDate() - 2); // 48 hours ago

      const { data: entries } = await supabase
        .from('entries')
        .select('*')
        .eq('userid', userId)
        .gte('createdat', last48Hours.toISOString());

      const { data: reflections } = await supabase
        .from('reflections')
        .select('*')
        .eq('userid', userId)
        .gte('createdat', last48Hours.toISOString());

      if ((entries.length === 0 || !entries) && (reflections.length === 0 || !reflections)) {
        // console.log('No entries or reflections in the last 48 hours. Resetting streak...');
        await supabase
          .from('users')
          .update({ streak: 0 })
          .eq('id', userId);
        setStreak(0);
      } else {
        const { data: userData } = await supabase
          .from('users')
          .select('streak')
          .eq('id', userId)
          .single();

        // console.log('User streak from DB:', userData.streak);
        setStreak(userData.streak);
      }
    };

    checkStreak();
  }, []); // Run only once on page load

  return (
    <div className="day-tracker">
      <div className="date-info">
        <h3>{formattedDate}</h3>
      </div>
      
      <div className="day-circles">
        {daysOfWeek.map((day, index) => (
          <div 
            key={index}
            className={`circle ${index === todayIndex ? 'today' : ''} ${daysWithEntries.has(index) ? 'filled' : ''}`}>
            <span className={daysWithEntries.has(index) ? 'day-text-filled' : 'day-text'}>{day}</span>
            {daysWithEntries.has(index) && <CheckIconFilled className="day-check-icon" />}
          </div>
        ))}
      </div>
      
      <div>
        <p className="streak-info"><span>🔥 Streak</span>{streak} day{streak !== 1 ? 's' : ''}</p>
      </div>
    </div>
  );
};

export default DayTracker;
