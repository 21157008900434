// src/App.js
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import WritePage from "./pages/WritePage";
import EntryPage from "./pages/EntryPage";
import MyPlant from "./pages/MyPlant";
import GoalsPage from "./pages/GoalsPage";
import "./App.css";
import Dashboard from "./pages/Dashboard";
import LoginPage from "./pages/LoginPage";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute"; // Import the ProtectedRoute component
import './mobile.css'
import TOSPage from "./pages/TOSPage";
import PrivacyPage from "./pages/PrivacyPage";

function App() {
  return (
    <Router>
      <Routes>
        {/* Public Routes */}
        <Route path="/login" element={<LoginPage />} />
        <Route path="/terms-of-service" element={<TOSPage />} />
        <Route path="/privacy-policy" element={<PrivacyPage />} />

        {/* Protected Routes */}
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/write"
          element={
            <ProtectedRoute>
              <WritePage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/entries"
          element={
            <ProtectedRoute>
              <EntryPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/my-plant"
          element={
            <ProtectedRoute>
              <MyPlant />
            </ProtectedRoute>
          }
        />
        <Route
          path="/my-goals"
          element={
            <ProtectedRoute>
              <GoalsPage />
            </ProtectedRoute>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
