import React, { useState } from 'react';
import { CancelIcon } from '../../assets/icons/icons';
import './style.css';
import { supabase } from '../../supabaseClient'; // Import supabase client
import LoadingAnimation from '../LoadingAnimation/LoadingAnimation'; // Import the loading animation component

const getQuestionsForReflection = (reflectionType) => {
    if (reflectionType.type === 'morning') {
        return [
            "What is your highest priority today?",
            "Is there anything worrying you about the day ahead?",
            "What part of today are you most looking forward to?",
        ];
    } else if (reflectionType.type === 'noon') {
        return [
            "How is your day going so far?",
            "What have you accomplished so far?",
            "Is there anything you'd like to change about your approach for the rest of the day?",
        ];
    } else {
        return [
            "What is something you're grateful for today?",
            "What were some of the challenges you faced today?",
            "What was something you learned today?",
        ];
    }
};

export default function ReflectionModal({ isOpen, closeModal, reflectionType, onSubmit }) {
    const [reflectionResponses, setReflectionResponses] = useState({});
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0); // Track current question
    const [isLoading, setIsLoading] = useState(false);
    const [affirmation, setAffirmation] = useState(''); // Affirmation state
    const questions = getQuestionsForReflection(reflectionType);
    const [successMessage, setSuccessMessage] = useState(null);

    const handleResponseChange = (question, response) => {
        setReflectionResponses({ ...reflectionResponses, [question]: response });
    };

    const handleNextQuestion = (e) => {
        e.preventDefault();
        if (currentQuestionIndex < questions.length - 1) {
            setCurrentQuestionIndex(currentQuestionIndex + 1); // Move to next question
        } else {
            handleSubmit(e); // Submit if it's the last question
        }
    };

    // Function to fetch AI responses from the backend
    const generateAIResponses = async (reflectionText) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/generate-reflection`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ entryText: reflectionText })
            });

            if (!response.ok) {
                throw new Error('Failed to fetch AI response');
            }

            const data = await response.json();
            return data;  // Returning the entire object { entryReflection, keyInsight, affirmation }
        } catch (error) {
            console.error('Error fetching AI response:', error);
            throw error;
        }
    };

    // Streak check functionality
    const updateStreak = async () => {
        const { data: { session } } = await supabase.auth.getSession();
        if (!session) return;

        const userId = session.user.id;

        // Fetch the current streak and last_updated timestamp
        const { data: userData, error } = await supabase
            .from('users')
            .select('streak, last_updated')
            .eq('id', userId)
            .single();

        if (error || !userData) {
            console.error('Error fetching user data:', error);
            return;
        }

        const lastUpdated = new Date(userData.last_updated);
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Set to the start of today

        // Check if last_updated is today, if not increment streak and update last_updated
        if (lastUpdated < today) {
            const newStreak = userData.streak + 1;
            const { error } = await supabase
                .from('users')
                .update({ streak: newStreak, last_updated: new Date() })
                .eq('id', userId);

            if (error) {
                console.error('Error updating streak:', error);
            }
        }
    };

    // Submit reflection responses to Supabase
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true); // Show loading

        const { data: { session } } = await supabase.auth.getSession();
        if (!session) {
            console.error('User is not authenticated');
            return;
        }

        const userId = session.user.id;
        const body = Object.values(reflectionResponses).join(" | "); // Concatenate answers with '|'

        try {
            // Call the backend to generate the AI response
            const aiResponse = await generateAIResponses(body);

            // Extract AI-generated data (reflection, insight, and affirmation) from the AI response object
            const { entryReflection, keyInsight, affirmation: affirmationText } = aiResponse;

            // Insert reflection into the database
            const { error } = await supabase
                .from('reflections')
                .insert([{
                    userid: userId,
                    title: reflectionType.title, // Use the reflection type as the title
                    body, // Answers are stored separated by '|'
                    createdat: new Date(),
                    type: reflectionType.type,
                    question: questions.join(" | "), // Save all questions separated by '|'
                    entry_reflection: entryReflection,
                    key_insight: keyInsight,
                    affirmation: affirmationText,
                }]);

            if (error) {
                throw error;
            }

            // Update the user's streak
            await updateStreak();
            setSuccessMessage('Reflection successfully added!')
            setAffirmation(affirmationText); // Set the generated affirmation
            setIsLoading(false); // Stop loading after submission

        } catch (error) {
            console.error('Error saving reflection:', error);
            setIsLoading(false); // Stop loading in case of an error
        }
    };
    

    if (!isOpen) return null;

    return (
        <div className="modal-overlay" onClick={closeModal}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                {isLoading ? (
                    <div className="animation-container">
                        <LoadingAnimation />
                    </div>
                ) : (
                    affirmation ? (
                        <>
                            <div className="affirmation-section">
                                <h3>Your Affirmation:</h3>
                                <p>{affirmation}</p>
                                <button onClick={() => { closeModal(); window.location.href = '/'; }}>Close</button>
                            </div>
                            {successMessage && <p className="success-message">{successMessage}</p>}
                        </>
                    ) : (
                        <>
                            <div className="goal-accordion-container">
                                <h3>{reflectionType.title}</h3>
                                <CancelIcon onClick={closeModal} className='cancel-icon'/>
                            </div>
                            <form onSubmit={handleNextQuestion}>
                                <label>
                                    {questions[currentQuestionIndex]}
                                    <textarea
                                        className='reflection-textarea'
                                        placeholder="Write..."
                                        value={reflectionResponses[questions[currentQuestionIndex]] || ""}
                                        onChange={(e) => handleResponseChange(questions[currentQuestionIndex], e.target.value)}
                                    />
                                </label>
                                <button type="submit">
                                    {currentQuestionIndex < questions.length - 1 ? "Continue" : `Finish ${reflectionType.title}`}
                                </button>
                            </form>
                        </>
                    )
                )}
            </div>
        </div>
    );
}
