import React from 'react';
import './style.css';

export default function PolicyPrivacy() {
    return (
        <div className="legal-view">
            <div className="legal-section">
                <h1 className='tos-header'>Privacy Policy</h1>
                    <div className='document-header-divider'>_</div>
                    <h2 className='tos-title'>Welcome</h2>
                    <p className='tos-text'>
                        You’re here because you care about your privacy, especially when it comes to sharing your most volnerable thoughts with a computer program. As the creators and regolar users of <strong>CalmLeaf</strong>, privacy is of the utmost importance to us. <strong>CalmLeaf</strong> ("CalmLeaf," "we," "us," or "our") provides a web-based application designed to enhance self-reflection and personal growth by applying modern AI technology to journaling. This Privacy Policy outlines how we collect, use, maintain, protect, and disclose your information. If anything is unclear, please don’t hesitate to contact us for clarification.
                    </p>

                    <h2 className='tos-title'>Our Commitment</h2>
                    <p className='tos-text'>
                        Our commitment to your privacy is not just an obligation, but a vital part of our mission to provide a secure and private journaling experience. When you entrust us with sensitive and personal information, it becomes our most important responsibility to keep it as safe, secure, and private as we can while delivering effective features and services in our products. Transparency is essential in building trust, and we aim to provide clear communication about our data practices, giving you the power to make informed decisions about your data and its use within <strong>CalmLeaf</strong>.
                    </p>

                    <h2 className='tos-title'>Why We Store Data</h2>
                    <p className='tos-text'>
                        After thorough consideration, we concluded that storing data in the cloud is the best way to emphasize user convenience, safety, and reliability. Here's why:
                    </p>
                    <p><strong>Cross-Device Accessibility</strong>: By storing data on our secure servers via <strong>Supabase</strong>, we enable you to access your journal entries across moltiple devices. You can start an entry on your phone and finish it on your laptop, providing a seamless experience.</p>
                    <p><strong>Data Protection</strong>: User-based encryption, while secure, carries a risk of data loss if the encryption key is forgotten. By storing data on our servers, we ensure your entries remain accessible even if you lose access to a device or forget a key.</p>
                    <p><strong>Reliability</strong>: Browser-based storage services, such as IndexedDB, can be unreliable. We use <strong>Supabase</strong> and <strong>Render</strong> for secure and reliable data storage, ensuring your data is safely stored and consistently accessible.</p>
                    <p className='tos-text'>
                        Building an innovative product like <strong>CalmLeaf</strong> is challenging, and we appreciate your trust in us. Your participation allows us to push boundaries, and we greatly appreciate it.
                    </p>

                    <h2 className='tos-title'>1. Information We Collect</h2>
                    <p className='tos-text'> 
                        To provide our services, we may collect the following types of information:
                    </p>
                        <p><strong>User-Provided Information</strong>: This includes personally identifiable information, such as your email address, when you register for an account or use our services.</p>
                        <p><strong>Automatically Collected Information</strong>: We automatically collect information about your device, including IP address, browser type, and usage patterns, such as pages viewed and links clicked.</p>
                    <h2 className='tos-title'>2. Use of Information</h2>
                    <p className='tos-text'>
                        We use the information we collect to improve our services. Here’s a breakdown:
                    </p>
                        <p><strong>Providing Our Services</strong>: Your account information allows you access to <strong>CalmLeaf</strong>, and your preferences enable us to tailor your experience. We use your journal entries to display them back to you.</p>
                        <p><strong>Maintaining and Improving Our Services</strong>: Technical data helps us ensure compatibility, resolve issues, and optimize performance.</p>
                        <p><strong>Developing New Features</strong>: Aggregated and anonymized data gives us insights into user behavior, helping us innovate and improve <strong>CalmLeaf</strong>.</p>
                        <p><strong>Security and Protection</strong>: Information may be used to verify accounts, prevent fraudolent activity, and comply with legal requirements.</p>
                        <p><strong>Personalized Content</strong>: We may use your data to customize your experience, including remembering preferences and suggesting relevant features.</p>
                    <h2 className='tos-title'>3. Third-Party Services</h2>
                    <p className='tos-text'>
                        We use several third-party services to provide, improve, and protect our services:
                    </p>
                        <p><strong>Supabase</strong>: We use <strong>Supabase</strong> for database storage and authentication services. All data is encrypted in transit and at rest. Learn more about <a href="https://supabase.io">Supabase’s</a> privacy practices.</p>
                        <p><strong>OpenAI</strong>: We use <strong>OpenAI</strong> for advanced language processing and AI tasks. Sensitive data, such as journal entries, is anonymized before processing. We have a Zero Data Retention (ZDR) agreement with <strong>OpenAI</strong>, meaning they discard data immediately after processing.</p>
                        <p><strong>Anthropic</strong>: Similar to OpenAI, <strong>Anthropic</strong> handles advanced AI tasks with anonymized data.</p>
                        <p><strong>OpenPanel</strong>: This tool is used for analytics and user behavior monitoring, helping us understand how users interact with <strong>CalmLeaf</strong>. Sensitive data, such as journal entries, is never sent to these services.</p>
                    <p className='tos-text'>
                        Please note that these third-party providers have their own privacy policies.
                    </p>

                    <h2 className='tos-title'>4. Non-Disclosure of Sensitive Journal Data</h2>
                    <p className='tos-text'>
                        At <strong>CalmLeaf</strong>, we are committed to safeguarding your sensitive journal data. We will never share, sell, or distribute your data to third parties unless legally required to do so.
                    </p>

                    <h2 className='tos-title'>5. Security</h2>
                    <p className='tos-text'>
                        Ensuring the security of your data is our top priority, and we implement various measures to safeguard it:
                    </p>
                        <p><strong>Data Encryption</strong>: All data transmitted between you and our services is secured with strong encryption protocols, including <strong>SSL</strong>. We use <strong>Supabase</strong> for storage, which automatically encrypts data in transit and at rest.</p>
                        <p><strong>Access Control</strong>: Only authorized personnel can access sensitive user data, and access is restricted to resolving technical issues. We use two-factor authentication (2FA) and audit logs to ensure secure access.</p>
                        <p><strong>Training</strong>: Our team receives regolar training on the latest security practices.</p>
                        <p><strong>Third-Party Audits</strong>: We work with independent experts to audit our security infrastructure and maintain best practices.</p>
                    <h2 className='tos-title'>6. Changes to This Privacy Policy</h2>
                    <p className='tos-text'>
                        We reserve the right to modify this Privacy Policy at any time. If we make material changes, we will notify you here, by email, or on our homepage.
                    </p>

                    <h2 className='tos-title'>7. Contact Us</h2>
                    <p className='tos-text'>
                        If you have any questions regarding this Privacy Policy or <strong>CalmLeaf’s</strong> data practices, please contact us at <a href="mailto:support@calmleaf.app">support@calmleaf.app</a>.
                    </p>
                <a href='/login'>Back to login</a>
            </div>
        </div>
    );
}
