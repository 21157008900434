import React, { useEffect, useState } from "react";
import GoalModal from "../GoalModal/GoalModal";
import GoalFlag from '../../assets/svgs/flag.svg'
import './style.css';
import { CheckIcon, ExpandIcon } from "../../assets/icons/icons";
import { supabase } from '../../supabaseClient'; // Import supabase client

const GoalTracker = () => {
  const [goals, setGoals] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCompletedOpen, setIsCompletedOpen] = useState(false);
  const [selectedGoal, setSelectedGoal] = useState(null); // For editing a goal

  // Fetch active goals from Supabase when component loads
  useEffect(() => {
    const fetchGoals = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session) return;

      const userId = session.user.id;
      const { data, error } = await supabase
        .from('goals')
        .select('*')
        .eq('userid', userId)
        .eq('status', true); // Only fetch active goals

      if (error) {
        console.error('Error fetching goals:', error);
      } else {
        setGoals(data); // Set only active goals
      }
    };

    fetchGoals();
  }, []);

  // Add goal to the state and to Supabase
  const addGoal = async (goal) => {
    const { data: { session } } = await supabase.auth.getSession();
    if (!session) return;
  
    const userId = session.user.id;
  
    const { data, error } = await supabase
      .from('goals')
      .insert([{ ...goal, userid: userId, createdat: new Date(), status: true }])
      .select(); // Ensure you're selecting the inserted data
  
    if (error) {
      console.error('Error adding goal:', error);
    } else if (data && data.length > 0) { // Ensure data exists and has at least one element
      setGoals([...goals, data[0]]); // Add new goal to the list
      // window.location.href = '/';
    }
  };
  

  // Open the modal for editing a goal
  const editGoal = (goal) => {
    setSelectedGoal(goal);
    setIsModalOpen(true);
  };

  // Update a goal in Supabase
  const updateGoal = async (goal) => {
    const { data, error } = await supabase
      .from('goals')
      .update(goal)
      .eq('id', goal.id)
      .select(); // Get the updated goal

    if (error) {
      console.error('Error updating goal:', error);
    } else if (data && data.length > 0) {
      const updatedGoals = goals.map(g => g.id === goal.id ? data[0] : g);
      setGoals(updatedGoals);
      window.location.href='/'
    }
  };

  // Mark goal as completed
  const markGoalAsCompleted = async (goalId) => {
    const completedAt = new Date(); // Get current date and time
    const { data, error } = await supabase
      .from('goals')
      .update({ status: false, completedat: completedAt }) // Set status to false to mark it as completed
      .eq('id', goalId)
      .select(); // Ensure we get the updated goal

    if (error) {
      console.error('Error marking goal as completed:', error);
    } else if (data && data.length > 0) {
      const updatedGoals = goals.map(g => g.id === goalId ? data[0] : g);
      setGoals(updatedGoals);
      window.location.href='/'
    }
  }; 

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
    setSelectedGoal(null); // Clear the selected goal when adding a new goal
  };

  return (
    <div className="goal-tracker">
      <h3>Goal Tracker</h3>
      <p>Tracking your goals is a major key in elevating your life and mental health</p>

      {/* Only render the goals section if there are active goals */}
      {goals.length > 0 && (
        <>
          <div className="goal-accordion">
            { isCompletedOpen ? (
              <>
                <div className="goal-accordion-container">
                  <p className="goal-completed">Active Goals</p>
                  <div className="goal-count-container">
                    <span>{goals.length}</span>
                    <ExpandIcon onClick={() => setIsCompletedOpen(false)} className='close-icon'/>
                  </div>
                </div>
                <div className="goal-map-container">
                  {goals.map((goal, index) => (
                    <AccordionItem key={index} goal={goal} editGoal={editGoal} markGoalAsCompleted={markGoalAsCompleted} />
                  ))}
                </div>
              </>
            ) : (
              <>
                <div className="goal-accordion-container">
                  <p className="goal-completed">Active Goals</p>
                  <div className="goal-count-container">
                    <span>{goals.length}</span>
                    <ExpandIcon onClick={() => setIsCompletedOpen(true)} className='open-icon'/>
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      )}
      <div className="reflection-art-container">
        <button onClick={toggleModal}>Add Goal</button>
        <img src={GoalFlag} className="reflection-art" alt="Reflection Art" />
      </div>
      <GoalModal 
        isOpen={isModalOpen} 
        closeModal={toggleModal} 
        addGoal={addGoal} 
        updateGoal={updateGoal} 
        selectedGoal={selectedGoal} // Pass selectedGoal for editing
        markComplete={markGoalAsCompleted} // Pass markGoalAsCompleted to the modal
      />
    </div>
  );
};

const AccordionItem = ({ goal, editGoal, markGoalAsCompleted }) => {
  return (
    <div className="goal-item-container">
      <div className="goal-item" onClick={() => editGoal(goal)}>
        <span className="goal-title">{goal.title}</span>
        <CheckIcon className='check-icon' onClick={() => markGoalAsCompleted(goal.id)} />
      </div>
    </div>
  );
};

export default GoalTracker;
